export default [
  {
    path: 'user/roles',
    name: 'user.roles',
    component: () => import('@/modules/users/roles/pages/list/index.vue'),
    meta: {}
  },
  {
    path: 'user/positions',
    name: 'user.positions',
    component: () => import('@/modules/users/positions/pages/list/index.vue'),
    meta: {}
  },
  {
    path: 'user/students',
    name: 'user.students',
    component: () => import('@/modules/users/students/pages/list/index.vue'),
    meta: {}
  },
  {
    path: 'user/students/create',
    name: 'user.students.create',
    component: () => import('@/modules/users/students/pages/create/index.vue'),
    meta: {}
  },
  {
    path: 'user/students/',
    name: 'user.students.current',
    component: () => import('@/modules/users/students/pages/main-view/index.vue'),
    children: [
      {
        path: ':id/personal-data',
        name: 'user.students.personal-data',
        component: () => import('@/modules/users/students/pages/info/index.vue')
      },
      {
        path: ':id/courses',
        name: 'user.students.courses',
        component: () => import('@/modules/users/students/pages/courses/index.vue')
      },
      {
        path: ':id/tariffs',
        name: 'user.students.tariffs',
        component: () => import('@/modules/users/students/pages/tariffs/index.vue')
      },
      {
        path: ':id/settings',
        name: 'user.students.settings',
        component: () => import('@/modules/users/students/pages/settings/index.vue')
      },
      {
        path: ':id/roles',
        name: 'user.students.roles',
        component: () => import('@/modules/users/students/pages/roles/index.vue')
      },
      {
        path: ':id/home-works/courses',
        name: 'user.students.home-works-courses',
        component: () => import('@/modules/users/students/pages/home-works-courses/index.vue')
      },
      {
        path: ':id/contacts-changes',
        name: 'user.students.contacts-changes',
        component: () => import('@/modules/users/students/pages/contacts-changes/index.vue')
      },
      {
        path: ':id/home-works/courses/:courseId/list',
        name: 'user.students.home-works-course-detail',
        component: () => import('@/modules/users/students/pages/home-works-course-detail/index.vue')
      }
    ]
  },
  {
    path: 'user/admins',
    name: 'user.admins',
    component: () => import('@/modules/users/admins/pages/list/index.vue'),
    meta: {}
  },
  {
    path: 'user/admins/create',
    name: 'user.admins.create',
    component: () => import('@/modules/users/admins/pages/create/index.vue'),
    meta: {}
  },
  {
    path: 'user/admins/',
    name: 'user.admins.current',
    component: () => import('@/modules/users/admins/pages/main-view/index.vue'),
    children: [
      {
        path: ':id/personal-data',
        name: 'user.admins.personal-data',
        component: () => import('@/modules/users/admins/pages/info/index.vue')
      },
      {
        path: ':id/courses',
        name: 'user.admins.courses',
        component: () => import('@/modules/users/admins/pages/courses/index.vue')
      },
      {
        path: ':id/courses-access',
        name: 'user.admins.courses-access',
        component: () => import('@/modules/users/admins/pages/courses-access/index.vue')
      },
      {
        path: ':id/tariffs',
        name: 'user.admins.tariffs',
        component: () => import('@/modules/users/admins/pages/tariffs/index.vue')
      },
      {
        path: ':id/home-works/courses',
        name: 'user.admins.home-works-courses',
        component: () => import('@/modules/users/admins/pages/home-works-courses/index.vue')
      },
      {
        path: ':id/countries',
        name: 'user.admins.countries',
        component: () => import('@/modules/users/admins/pages/countries/index.vue')
      },
      {
        path: ':id/roles',
        name: 'user.admins.roles',
        component: () => import('@/modules/users/admins/pages/roles/index.vue')
      },
      {
        path: ':id/settings',
        name: 'user.admins.settings',
        component: () => import('@/modules/users/admins/pages/settings/index.vue')
      },
      {
        path: ':id/permissions',
        name: 'user.admins.permissions',
        component: () => import('@/modules/users/admins/pages/permissions/index.vue')
      },
      {
        path: ':id/contacts-changes',
        name: 'user.admins.contacts-changes',
        component: () => import('@/modules/users/admins/pages/contacts-changes/index.vue')
      },
      {
        path: ':id/home-works/courses/:courseId/list',
        name: 'user.admins.home-works-course-detail',
        component: () => import('@/modules/users/admins/pages/home-works-course-detail/index.vue')
      }
    ]
  }
]
